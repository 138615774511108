.filter-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .filter-popup {
    
    background: #fff;
    padding: 20px;
    border-radius: 8px;

  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .filters input {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
  }
  