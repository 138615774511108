.role-modal {
    width: 30vw;
}

/* For Roles Page */
.table-container-roles {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.table-header-roles {
  background-color: #f8f9fa;
  padding: 12px;
  font-weight: bold;
  border-bottom: 2px solid #e9ecef;
  display: flex;
}

.table-row-roles {
  background-color: #fff;
  padding: 10px 12px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
  display: flex;
}

.table-row-roles:hover {
  background-color: #f1f3f5;
}

.table-cell-roles {
  padding: 5px;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box;
}

.table-cell-roles:nth-child(1) { 
  width: 10%;
}

.table-cell-roles:nth-child(2) { 
  width: 80%;
}

.table-cell-roles:nth-child(3) { 
  width: 10%;
}

.icon-btn-roles {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text-roles {
  text-align: center;
  font-weight: bold;
  color: #007bff;
}

.search-roles {
  border-radius: 10px !important;
  font-size: 16px !important;
  padding: 10px 20px !important;
  background-color: var(--input-bg) !important;
  border: solid 1px #E7EAED !important;
  font-weight: 500 !important;
  color: var(--text-primary) !important;
  width: 100% !important;
  outline: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
}
