.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.modal > div {
    position: relative;
    border-radius: 5px;
    width: max-content;
    padding: 1.5rem;
    height: fit-content;
    background-color: #FCFCFC;
}

.modal .close-modal {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50px;
    transition: 0.3s all ease;
    background-color: #303030;
    height: 35px;
    width: 35px;
    color: white;
    cursor: pointer;
    border: none;
    &:hover{
        background-color: #1683FF;
        transform: translate(0, -2px);
    }
}

.modal2 > div {
    position: relative;
    border-radius: 5px;
    padding: 1rem;
    width: 50vw;
    height: fit-content;
}