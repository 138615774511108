#navbar {
  display: flex;
  gap: 0.5rem;
  background-color: #f2f4fa60;
  border-bottom: solid 1px #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  padding: 1rem 1rem;
  z-index: 1;
  align-items: center;
}

#navbar.sidebar-right > div:first-child {
  order: 1;
}

#navbar .settings svg {
  transition: transform 0.3s;
}
#navbar .settings:hover svg {
  transform: rotateZ(60deg);
}
#navbar .settings.active svg {
  transform: rotateZ(60deg);
}

#navbar .toggle-sidebar-open {
  display: none;
}

@media only screen and (max-width: 1200px) {
  #navbar .toggle-sidebar-expanded {
    display: none !important;
  }
  #navbar .toggle-sidebar-open {
    display: block;
  }
}
.dashboardButton {
  text-decoration: none;
  background: #fff;
  border: 1px solid #EAECF0;
  color: #333333 !important;
  border-radius: 50px;
  position: absolute;
  top: 21px;
  right: 35px;
  transition: 0.3s all ease;
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05); */
}
.dashboardButton:hover {
  background: #333333;
  color: #fff !important;
}
