.table-container2 {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.table-header2 {
  background-color: #f8f9fa;
  padding: 12px;
  font-weight: bold;
  border-bottom: 2px solid #e9ecef;
  display: flex; /* Use flex to align header cells */
}
.btn-primary-active-perm{
  background-color: var(--btn-primary-active-perm);
}
.table-row2 {
  background-color: #fff;
  padding: 10px 12px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
  display: flex; /* Use flex to align row cells */
}

.table-row2:hover {
  background-color: #f1f3f5;
}

.table-cell2 {
  padding: 5px;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

/* Set fixed widths for each cell */
.table-cell2:nth-child(1) { 
  width: 25%;
}

.table-cell2:nth-child(2) { 
  width: 35%;
}

.table-cell2:nth-child(3) { 
  width: 20%; 
}

.table-cell2:nth-child(4) {
  width: 12%; 
}
.icon-btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text2 {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 640;
  color: #303030;
  padding: 100px 0;
}

.textt{
    font-size: 15px;
    font-weight: 600;
    
}





