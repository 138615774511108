#support-plan-payments {
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .tab {
    padding: 9px 24px; /* Increased padding for a better touch area */
    border: none;
    background-color: #666666;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
    color: white;
    font-weight: 600;
    font-size: 1rem;
  }

  .tab.active {
    background-color: #333333;
  }

  .tab:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .page {
    width: 480px;
    padding: 20px;
    font-size: 12px;
    font-family: "Helvetica";
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px ;
    text-align: center;
    border-bottom: 2px solid #1683ff;
  }

  .invoiceTitle {
    font-size: 30px;
    font-weight: bold;
    color: #1683ff;
    text-align: left;
    text-transform: uppercase;
  }

  .invoiceDate {
    font-size: 14px;
    font-weight: bold;
    color: #343434;
  }

  .clientInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border: 1px solid #ddd;
  }

  .clientInnerDetails {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .sectionTitle {
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    color: #fff;
    background-color: #1683ff;
    border-bottom: 1px solid #fff;
  }

  .sectionTitleInvert {
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    color: #fff;
    background-color: #000;
    border-bottom: 1px solid #fff;
  }

  .clientDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
  }

  .invoiceDetails {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    margin-bottom: 20px;
  }

  .detailsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 10px;
  }

  .label {
    font-size: 15px;
    font-weight: bold;
    color: #1683ff;
  }

  .text {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-left: 5px;
  }

  .messageSection {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 1px solid #ddd;
  }

  .messagep {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-top: 1px solid #1683ff;
    padding-top: 20px;
    text-align: right;
  }

  .footerp {
    font-size: 12px;
    font-weight: bold;
    color: #1683ff;
  }

  .detailsFirstRow {
    display: flex;
    flex-direction: row;
    gap: 10;
    width: 50%;
  }
}
