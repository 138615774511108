.plans-table {
  height: 640px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.MuiDataGrid-columnHeader {
  background-color: #fff !important;
  border-bottom: 2px solid #e2e2e2;
  font-weight: bold;
  padding: 0 15px !important;
}

.MuiDataGrid-columnHeaderTitle{
  font-size: 15px !important;
  font-weight: 650 !important;
}

.MuiDataGrid-cell {
  font-weight: 500;
  font-size: 14px !important;
  color: #1e1e1e;
  cursor: pointer;
  outline: none !important;
}

.MuiDataGrid-row {
  transition: all ease 0.3s;
  padding: 0 10px !important;
}

.MuiDataGrid-row:hover {
  background-color: #C3E6F6 !important;
}

#support-plans {
  .status {
    padding: 7px 14px;
    border-radius: 20px;
    border: 2.5px solid;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
  }

  .status-active {
    background-color: rgb(196, 255, 196);
    border-color: rgb(34, 236, 34);
    color: rgb(34, 236, 34);
    transition: all ease 0.3s;
    &:hover {
      background-color: rgb(34, 236, 34);
      color: white;
    }
  }

  .status-new {
    background-color: rgb(199, 225, 255);
    border-color: rgb(22, 131, 255);
    color: rgb(22, 131, 255);
    transition: all ease 0.3s;
    &:hover {
      background-color: rgb(22, 131, 255);
      color: white;
    }
  }

  .status-archive {
    background-color: #fff2b8;
    border-color: #ffd30d;
    color: #ffd30d;
    transition: all ease 0.3s;
    &:hover {
      background-color: #ffd30d;
      color: white;
    }
  }

  .status-due {
    background-color: rgb(255, 201, 201);
    border-color: rgb(255, 68, 68);
    color: rgb(255, 68, 68);
    transition: all ease 0.3s;
    &:hover {
      background-color: rgb(255, 68, 68);
      color: white;
    }
  }

  .chips {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .chip-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .chip {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0.7rem 1.4rem;
    border-radius: 50px;
    background-color: #666666;
    color: white;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .chip.active {
    background-color: #333333;
  }

  .chip:not(.active):hover {
    background-color: #333333;
    transform: translateY(-0.5px);
  }

  .support-states-container-grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 20px 0;
    background: white;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #EAECF0;
  }

  .support-states-container-grid-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 20px 0 30px 0;
  }

  .support-link {
    text-decoration: none;
    flex: 1;
    color: #555555;
  }

  .support-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    background-color:  #FBFCFC;
    border: 1px solid #EAECF0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.00);
    transition: 0.5s all ease;
    & h2 {
      color: #007bff;
      font-size: 30px;
      font-weight: bold;
    }
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }
  }

}
  .mailInfo {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border: 1px solid #007bff;
    background-color: var(--bg-primary);
    border-radius: 5px;
    color: black;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.025);
  }

  .to {
    border: 1px solid #007bff;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 20px;
    font-weight: 600;
    background-color: var(--bg-primary);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.025);
  }
  .filters{
    display: flex;
    align-items: center;
    width: 50%;
    gap: 1rem;
    position: relative;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  .pay::placeholder {
    color: black; 
    opacity: 1; 
  }

  .purple-button{
    font-size: 15px;
    font-weight: bold;
    padding: 11px 20px;
    border-radius: 20px;
    background-color: rgb(80, 38, 197);
  }