#sidebar {
  width: 250px;
  z-index: 10;
  transition: width 0.2s;
  background-color: #fff;
}
#sidebar.right {
  order: 1;
}
#sidebar.closed {
  width: 0;
}
#sidebar > div {
  position: fixed;
  width: 250px;
  left: 0;
  background-color: #fff;
  transition: left 0.2s;
  padding: 0 15px;
  overflow-y: scroll;
  
}
#sidebar.right > div {
  right: 0;
  left: auto;
  border-right: none;
  border-left: solid 1px var(--border-primary);
  box-shadow: -2px 0 3px 0 var(--shadow-primary);
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
#sidebar.closed > div {
  left: -300px;
}
#sidebar.right.closed > div {
  right: -300px;
  left: auto;
}
#sidebar.closed > div.expanded {
  left: 0;
}
#sidebar.right.closed > div.expanded {
  right: 0;
  left: auto;
}
#sidebar > div > div:first-child {
  height: 78px;
  border-bottom: 1px solid #30303010;
}
#sidebar > div > div:first-child > button {
  margin-right: 1rem;
}
#sidebar.right > div > div:first-child > button {
  order: 1;
  margin-left: auto;
  margin-right: 0;
}

#sidebar > div > div:nth-child(2) ul {
  margin: 0;
  padding: 0.5rem 0;
}
#sidebar > div > div:nth-child(2) li {
  list-style: none;
}
#sidebar > div > div:nth-child(2) li > a, .navTitles a{
  padding: 22px 1rem !important;
  display: flex;
  text-decoration: none;
  /* background-color: #f0f6ff; */
  color: #202020 !important;
  font-size: 15px;
  font-weight: 550;
  height: 20px !important;
  align-items: center;
  border-radius: 10px;
}
/*#sidebar > div > div:nth-child(2) li:first-child > a {
  background-color: #f0f6ff; 
}*/
#sidebar > div > div:nth-child(2) li > a > svg {
  padding-right: 1rem;
}
#sidebar > div > div:nth-child(2) li > a:hover, .navTitles a:hover {
  background-color: #41AFE250;
  /* color: #06003b; */
  color: #1683FF !important;
}
#sidebar > div > div:nth-child(2) li > a{
  transition: 0.3s all ease;
}
#sidebar > div > div:nth-child(2) li > a.active, .navTitles a.active{
  background-color: #41AFE250;
  color: #1683FF !important;
}
#sidebar .dropdown > div:first-child {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 1rem;
  color: var(--text-secondary);
}
#sidebar .dropdown > div:first-child:hover {
  color: var(--text-highlight);
}
#sidebar .dropdown > div:first-child > svg {
  padding-right: 1rem;
}
#sidebar .dropdown > div:nth-child(2) {
  position: relative;
  max-height: 0px;
  transition: max-height 0.3s ease-in-out;
}
#sidebar .dropdown.open > div:nth-child(2) {
  max-height: 500px;
}
#sidebar .dropdown:not(.section) > div:nth-child(2) > div {
  position: relative;
  width: 100%;
  background-color: var(--sidebar-dropdown-bg);
  overflow: hidden;
}
#sidebar .dropdown > div:nth-child(2) > div > li > a {
  padding-left: 1.5rem;
}
#sidebar .dropdown-toggle > h6 > svg {
  transition: transform 0.2s;
}
#sidebar .dropdown.open > .dropdown-toggle > h6 > svg {
  transform: rotateZ(90deg);
}
#sidebar .section > div:last-child {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
#sidebar .section.open > div:last-child {
  max-height: 500px;
}
#sidebar .section-toggle {
  padding: 1rem;
  color: #303030;
}
#sidebar .section-toggle > h3 {
  font-size: 16px;
}
#sidebar .section-toggle > h5 > svg {
  transition: transform 0.2s;
}
#sidebar .section.open .section-toggle > h5 > svg {
  transform: rotateZ(90deg);
}
#sidebar .close-sidebar {
  display: none;
}

@media only screen and (max-width: 1200px) {
  #sidebar {
    width: 0 !important;
  }
  #sidebar .toggle-sidebar-expanded {
    display: none !important;
  }
  #sidebar .close-sidebar {
    display: block;
  }
}
.navTitles .clickable{
  border-radius: 10px;
  margin-bottom: 5px;
  transition: 0.5s all ease;
  &:hover {
    background-color: #41AFE250;
  }
}
#sidebar ::-webkit-scrollbar {
  width: 3px;
}
#sidebar ::-webkit-scrollbar-track {
  background: #fff; 
}
#sidebar ::-webkit-scrollbar-thumb {
  background: #66666610; 
  border-radius: 10px;
  margin: 10px 0px;
  transition: .5s all
}
#sidebar ::-webkit-scrollbar-thumb:hover {
  background: #666666; 
}
.sidebarIcon{
  font-size: 21px;
  padding-right: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  opacity: 0.9;
}