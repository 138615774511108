.contact-modal {
  height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-modal .additional-fields {
  padding-top: 2rem;
}
.contact-modal .additional-fields > div:nth-child(2) {
  overflow-y: auto;
  height: 35vh;
  max-height: 35vh;
  padding: 2px 4px 2px 2px;
}

.delete-contact-modal {
  padding: 2rem 2rem 0 2rem;
}
.delete-contact-modal button {
  margin-top: 2rem;
}

.contact-type-modal {
  width: 25vw;
  min-width: 500px;
  height: max-content;
}

.contact-field-modal {
  width: 25vw;
  min-width: 500px;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-type.color1 {
  background-color: var(--contat-type-color1);
}
.contact-type.color2 {
  background-color: var(--contat-type-color2);
}
.contact-type.color3 {
  background-color: var(--contat-type-color3);
}
.contact-type.color4 {
  background-color: var(--contat-type-color4);
}
.contact-type.color5 {
  background-color: var(--contat-type-color5);
}
.contact-type.color6 {
  background-color: var(--contat-type-color6);
}
.contact-type.color7 {
  background-color: var(--contat-type-color7);
}



.btn-rounded{
  border-radius: 50px;
}

.btn-icons{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.table-container {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table-header {
  background-color: #f8f9fa;
  padding: 12px;
  font-weight: bold;
  border-bottom: 2px solid #e9ecef;
  display: flex; /* Use flex to align header cells */
}

.table-row {
  background-color: #fff;
  padding: 10px 12px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
  display: flex; /* Use flex to align row cells */
}

.table-row:hover {
  background-color: #f1f3f5;
}

.table-cell {
  padding: 5px;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

/* Set fixed widths for each cell */
.small-width {
  width: 10%;
}
.table-cell:nth-child(3) { 
  width: 25%;
}

.table-cell:nth-child(4) { 
  width: 20%; 
}

.table-cell:nth-child(5) {
  width: 15%; 
}

.table-cell:nth-child(6) { 
  width: 10%; 
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  text-align: center;
  font-weight: bold;
  color: #007bff;
}