.auth-form {
    padding: 2rem;
    max-width: 26vw;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #EAECF0;
    min-width: 300px;
}
.auth-form button[type="submit"] {
    margin-top: 1rem;
}