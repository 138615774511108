.projects-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
}

.projects-container .project {
  height: fit-content;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 10px;
}

.projects-container .project:hover {
  transform: translateY(-3px);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
}

.projects-container .project > div {
  padding: 1.5rem;
  background-color: var(--bg-primary);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #dbdbdb9a;
  transition: border-color 0.3s;
}

.projects-container .project:hover > div {
  border-color: var(--btn-primary);
}

.projects-container .project p {
  padding: 0.5rem 0;
  font-size: 15px;
  color: #232323;
  line-height: 1.5;
}

.projects-container .project > div > div:nth-child(1) {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.projects-container .project > div > div:nth-child(3) {
}

.projects-container .grade {
  display: inline-block;
  background-color: var(--btn-primary);
  color: #fff;
  padding: 0.25rem 0.6rem;
  font-size: 13px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.projects-container .project.follow-up-1 .grade {
  background-color: var(--text-success);
}

.projects-container .project.follow-up-2 .grade {
  background-color: var(--text-warning);
}

.projects-container .project.follow-up-1 > div {
  box-shadow: 0 0 0 2px var(--text-success);
}

.projects-container .project.follow-up-2 > div {
  box-shadow: 0 0 0 2px var(--text-warning);
}

.follow-up-1-list {
  color: var(--text-success);
}

.follow-up-2-list {
  color: var(--text-warning);
}

.project-modal {
  width: 30vw;
  min-width: 400px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.context-menu {
  background: var(--bg-primary);
  box-shadow: 0 2px 10px var(--shadow-primary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;
  width: 220px;
}
.context-menu > div {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 1.3rem 0.8rem;
  height: 35px;
  color: var(--text-secondary);
  border-top: 1px solid rgb(228, 228, 228);
}
.context-menu > div:nth-child(1) {
  border-top: none !important;
  padding: 1.35rem 0.8rem;
}
.context-menu > div > svg {
  padding-right: 10px;
}
.context-menu > div:hover {
  cursor: pointer;
  background-color: rgba(var(--bg-highlight-rgb), 0.7);
  color: var(--btn-color);
}

/* .files-container {
  padding: 0.2rem 0;
} */
.dropdown-container > div:first-child {
  padding: 0.5rem 0;
  padding-right: 0.8rem;
  display: flex;
  align-items: center;
  color: var(--text-primary);
}
.dropdown-container > div:first-child > svg {
  font-size: 12px;
  margin-left: auto;
  transition: transform 0.2s;
}
.dropdown-container > div:first-child.open > svg {
  transform: rotateZ(90deg);
}
.dropdown-container > div:first-child:hover {
  cursor: pointer;
}
.dropdown-container > div:last-child {
  overflow: hidden;
  height: 0px;
  transition: height 0.2s;
}
.dropdown-container > div:last-child > div {
  background-color: var(--bg-secondary);
  padding: 1rem 1rem;
  border-radius: 5px;
}
.dropdown-container > div:last-child > div > div {
  padding: 0.2rem 0;
}
.dropdown-container > div:last-child > div > div > svg:first-child {
  margin-right: 0.5rem;
}
.dropdown-container .download-btn:hover {
  color: var(--text-highlight);
}

.MuiDataGrid-cell--textLeft {
  font-size: 12px !important;
}

.mt-3 {
  margin-top: 1rem;
}

.MuiDataGrid-virtualScrollerContent {
  background: white !important;
}

.MuiDataGrid-filler {
  background: white !important;
}

.pproject {
  border-radius: 50px;
  padding: 10px 20px;
  text-decoration: none;
}

.MuiDataGrid-columnHeader {
  background-color: #f8f9fa;
  border-bottom: 2px solid #e2e2e2;
  font-weight: bold;
  padding: 0 15px !important;
}

.MuiDataGrid-cell {
  font-weight: 500;
  font-size: 14px !important;
  color: #1e1e1e;
  cursor: pointer;
  outline: none !important;
}

.MuiDataGrid-row {
  transition: all ease 0.3s;
  padding: 0 10px !important;
}

.MuiDataGrid-row:hover {
  background-color: #f1f3f5;
}
