.dashboard {
  background-color: var(--bg-secondary);
  font-family: "Arial", sans-serif;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard h3 {
  font-size: 25px;
  color: #333;
}

.btn-refresh {
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-refresh:hover {
  background-color: #0056b3;
}

.project-states-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #EAECF0;
}

.project-link {
  text-decoration: none;
  flex: 1;
}

.project-card {
  background-color: #fbfcfc;
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 21px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.01);
  transition: 0.5s all ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.summary-container,
.revenue-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #EAECF0;
}

.summary-box,
.revenue-box {
  flex: 1;
  background-color: #fbfcfc;
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 21px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.01);
  transition: 0.5s all ease;
}

.summary-box:hover,
.revenue-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.summary-link,
.revenue-link {
  text-decoration: none;
}

.dashboard h2 {
  font-size: 2rem;
  padding-bottom: 5px;
  color: #007bff;
}

.dashboard h4 {
  font-size: 1rem;
  color: #555;
}

.filters {
  display: flex;
  width: 50%;
  gap: 1rem;
  position: relative;
  margin-left: auto;
}
.filters-box {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 30%;
}
