:root {
  --pulseSize: 10px;
}

.monitor-states-container-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.sections{
  background: white;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.monitor-states-container-grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  
}
.monitor-link {
  text-decoration: none;
  flex: 1;
  color: #444444;
}


.monitor-card-server,
.monitor-card-types {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color:  #FBFCFC;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); */
  transition: 0.5s all ease;
}
.monitor-card-server:hover,
.monitor-card-types:hover {
  /* background-color: #ebf5ff;
  border: 1px solid #99CCFF; */
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.monitor-card-server {
  height: 150px;
}
.monitor-card-types {
  height: 125px;
}

.monitor-card-server h3,
.monitor-card-types h3 {
  font-size: 1.2em;
}

.monitor-card-server p,
.monitor-card-types p {
  font-size: 2.2rem;
  font-weight: 800;
  color: var(--btn-primary);
}

.pulse-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulse {
  width: var(--pulseSize);
  height: var(--pulseSize);
  border-radius: 50%;
  background: var(--pulseColor, rgba(95, 139, 250, 0.65));
  box-shadow: 0 0 0 var(--pulseColor, rgba(95, 139, 250, 0.65));
  animation: pulsing 2s infinite;
}

@keyframes pulsing {
  from {
    box-shadow: 0 0 0 0 var(--pulseColor, rgba(95, 139, 250, 0.65));
  }
  70% {
    box-shadow: 0 0 0 var(--pulseSize) rgba(95, 139, 250, 0);
  }
  to {
    box-shadow: 0 0 0 0 rgba(95, 139, 250, 0);
  }
}

.coming-soon{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background: #ffffff99 !important;
  border-radius: 10px;
  color: #303030;
  z-index: 1;
}

.absolute-icons{
  position:absolute;
  top: 10px;
  right:10px;
  z-index: 11;
  }