/* Main Container */
#settings {
}

#settings h1 {
  font-size: 2rem;
  color: var(--text-primary, #333);
}

#settings > section {
  margin-top: 2rem;
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  border: 1px solid #EAECF0;
  transition: all 0.3s ease;
}
#settings > section:hover {

  border: 1px solid #99CCFF;
}

#settings > section h3 {
  font-size: 1.4rem;
  color: var(--text-primary, #333);
  margin-bottom: 0.5rem;
}

#settings > section p {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

/* Button Container */
#settings > section > div {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

/* Buttons */
#settings > section > div > button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.2rem;
  min-width: 150px;
  height: 42px;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  background-color:  #FBFCFC;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Hover and Active States */
#settings > section > div > button:hover {
  background-color: var(--hover-bg, #1683FF);
  color: #fff;
  border-color: var(--hover-bg, #1683FF);
  transform: translateY(-2px);
}

#settings > section > div > button:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}


/* Responsive Design */
@media (max-width: 480px) {
  #settings {
    padding: 1.5rem;
  }

  #settings > section > div > button {
    min-width: 120px;
    font-size: 0.9rem;
  }
}
